import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {PantasmaListApi} from './pantasma-api.interface';
import {Injectable, ViewChild, EventEmitter} from '@angular/core';
import {NotificationsComponent} from '../../notifications/notifications.component';
import {catchError, map, tap, startWith, switchMap} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { TokenStorageService } from 'app/_services/token-storage.service';
import { request } from 'http';


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
const AUTH_API = environment.serverUrl + '/api/auth/';


@Injectable()
export class PantasmaHttpClient {

    @ViewChild(NotificationsComponent) notifications: NotificationsComponent;

    

    urlBase = environment.serverUrl + '/api/private/';
    public change: EventEmitter<string> = new EventEmitter<string>();
    public changeDetail: EventEmitter<string> = new EventEmitter<string>();
    public changeRelationDetail: EventEmitter<string> = new EventEmitter<string>();
    public createRelationDetail: EventEmitter<string> = new EventEmitter<string>();
    public onSave: EventEmitter<string> = new EventEmitter<string>();
    public comboLoaded: EventEmitter<string> = new EventEmitter<string>();
    public otherChange: EventEmitter<string> = new EventEmitter<string>();
    public updateGrafico: EventEmitter<string> = new EventEmitter<string>();
    public updateGraficoNoSave: EventEmitter<string> = new EventEmitter<string>();

    constructor(private _httpClient: HttpClient, private tokenStorageService:TokenStorageService) {}

    forceOnSave(requestMapping){
        this.onSave.emit(requestMapping + "-update");
    }

    forceOnSaveWithoutRefresh(requestMapping){
        this.onSave.emit(requestMapping+"-noupdate");
    }

    forceRefresh(id, requestMapping){
        if(requestMapping == 'serumLab'){
            this.changeRelationDetail.emit(id + "-" + requestMapping); 
        }
        else{
            this.changeDetail.emit(id + "-" + requestMapping);
        }
        
    }

    refreshToken(token: string): Observable<any> {
        return this._httpClient.post(AUTH_API + 'refresh-token', {
          refreshToken: token
        }, httpOptions);
    }

    getList(sort: string, order: string, page: any, requestMapping: string, pageSize: any, filters: any, filterFields: any, filtersFrom: any, filtersTo: any, idById?: any, parentMapping?: any): Observable<PantasmaListApi> {
        
        var self = this;
        var filter = this.getFilters(filters,filterFields);
        var dates = this.getDates(filtersFrom, filtersTo, filterFields);

        if(idById!=null){
            filter[parentMapping + "Id"] = idById;
        }

        var query = {
            item: filter,
            dates: dates
        };

        const href = this.urlBase + requestMapping + '/find';
        const requestUrl =
            `${href}?order=${sort}&direction=${order}&page=${page}&size=${pageSize}`;

            setTimeout(function(){

                self.refreshToken(self.tokenStorageService.getRefreshToken()).subscribe(next => {
                    self.tokenStorageService.saveToken(next.accessToken);
                }, err => {
                                      
                });

            },2);
        // const headers = new HttpHeaders({'Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods':'GET,POST,OPTIONS,DELETE,PUT'});
        return this._httpClient.post<PantasmaListApi>(requestUrl, query, httpOptions);
    }
  
    getDates(filtersFrom: string[], filtersTo: string[], filterFields: string[]):any{

        var list = [];
        
        filterFields.forEach((element,index) =>{

          var json = {};
          if(filtersFrom[index]!='' && filtersFrom[index] != null){
           
             
              json = {
                    from: filtersFrom[index],
                    field: element
              };
          
            list.push(json);
          }

          if(filtersTo[index]!='' && filtersTo[index] != null){
           
             
            json = {
                  to: filtersTo[index],
                  field: element
            };
        
             list.push(json);
            }

        });
    
        return list;
    
    }

    getFilters(filters:string[],filterFields: string[]):any{

        var json = {};
    
        filterFields.forEach((element,index) =>{
          if(filters[index]!=''){
            if(element.indexOf('.')!= -1){
              if(json[element.split(".")[0]] == undefined){
                json[element.split(".")[0]] ={};
              }
              json[element.split(".")[0]][element.split(".")[1]] = filters[index];
            }
            else{
              json[element] = filters[index];
            }
    
          }
        });
    
        return json;
    
    }

    deleteItem(id: number, requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/' + id;
        return this._httpClient.delete(requestUrl).pipe(
                map(result => {
                    self.change.emit("change");
                })
            );
    }

    getItem(id: number, requestMapping: string) {
        const requestUrl = this.urlBase + requestMapping + '/' + id;
        return this._httpClient.get(requestUrl)
    }

    createItem(item, requestMapping: string, main: boolean, other: boolean = false){

        
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/';
        return this._httpClient.post(requestUrl, item, httpOptions).pipe(
            map(result => {

                if(!other){
                    if(main){
                        self.changeDetail.emit(result['id']+ "-" + requestMapping);
                    }
                    else{
                        self.changeRelationDetail.emit(result['id'] + "-" + requestMapping);
                        self.createRelationDetail.emit(result['id'] + "-" + requestMapping);
                        
                    }
                }
                else{
                    return result;
                }
                
            })
        );
      
    }

    updateItem(item, requestMapping: string, main: boolean, other: boolean = false){
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/' + item['id'] + '/';
        return this._httpClient.put(requestUrl, item, httpOptions).pipe(
            map(_ => {

                if(!other){
                    if(main){
                        self.changeDetail.emit(item['id'] + "-" + requestMapping);
                    }
                    else{
                        self.changeRelationDetail.emit(item['id'] + "-" + requestMapping);
                        if(requestMapping == 'arterialdos'){
                            self.updateGrafico.emit(item['diagnostico'] + "-" + item['intervencion']);
                        }
                        if (requestMapping == 'arterialcinco') {
                            self.updateGraficoNoSave.emit(item['diagnostico'] + '-' + item['intervencion']);
                        }
                        
                    }
                }
                else{
                    if(requestMapping == 'arterialdos'){
                        self.updateGraficoNoSave.emit(item['diagnostico'] + "-" + item['intervencion']); 
                    }
                    if (requestMapping == 'arterialcinco') {
                        self.updateGraficoNoSave.emit(item['diagnostico'] + '-' + item['intervencion']);
                    }
                }
               
            })
        );
    }

    updateJSON(item, requestMapping: string, main: boolean){
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/updateJSON/' + item['id'] + '/';
        return this._httpClient.put(requestUrl, item.properties, httpOptions).pipe(
            map(result => {
               return result;
            })
        );
    }

    getMetadata(requestMapping: string) {
        const requestUrl = this.urlBase + requestMapping + '/metadata/';
        return this._httpClient.get(requestUrl);
    }

    export(format: String, requestMapping: string, columns: string[], type: string, ids: number[], idById?: any, parentMapping?: any, userCode?: String){
        const requestUrl = this.urlBase + requestMapping + '/export/';
        var filter={};
       
        if(idById!=null){
            filter[parentMapping + "Id"] = idById;
        }

        var json ={
            extension:format,
            filter:filter,
            fields: columns,
            ids: ids,
            code: userCode
        }
        
        return this._httpClient.post(requestUrl,json,{ responseType: 'blob' });

    }

    exportLesions( requestMapping: string, parentId: number){

        const requestUrl = this.urlBase + requestMapping + '/exportLesions/' + parentId;
        return this._httpClient.post(requestUrl,{},{ responseType: 'blob' });
    }

    activate(id: number, requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/activate';
        return this._httpClient.put(requestUrl,{}).pipe(
                map(result => {
                    self.change.emit("change");
                })
            );
    }

    changeState(state: string, id: number, requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/changeState/' + id + '/' + state;
        return this._httpClient.put(requestUrl,{}).pipe(
                map(result => {
                    self.change.emit("change");
                    return result;
                })
            );
    }

    changeCampoRaro(campoRaro: string, campoRaro2: string,id: number, requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/campoRaro/' + id + '/' + campoRaro + '/' + campoRaro2;
        return this._httpClient.put(requestUrl,{}).pipe(
                map(result => {
                    self.change.emit("change");
                    return result;
                })
            );
    }

    changeStateFormulario(state: string, id: number, formulario: string, requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/changeStateFormulario/' + id + '/' + formulario + '/' + state;
        return this._httpClient.put(requestUrl,{}).pipe(
                map(result => {
                        self.changeDetail.emit(id + "-" + requestMapping);
                })
            );
    }

    citar(id: number, requestMapping: string, date: string, hora:string) {
        var self=this;
        if(hora==undefined || hora == null || hora ==''){
            hora="vacio";
        }
        const requestUrl = this.urlBase + requestMapping + '/citar/' + id + '/' + date + '/' + hora;
        return this._httpClient.put(requestUrl,{}).pipe(
                map(result => {
                    self.change.emit("change");
                    return result;
                })
            );
    }

    changeStateMultiple(state: string,  ids: number[], requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/changeState/' + state;
        var json ={
            ids: ids
        }
        return this._httpClient.put(requestUrl,json).pipe(
                map(result => {
                    self.change.emit("change");
                })
            );
    }

    deactivate(id: number, requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/deactivate';
        return this._httpClient.put(requestUrl,{}).pipe(
                map(result => {
                    self.change.emit("change");
                })
            );
    }

    clone(id: number, requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/clone';
        return this._httpClient.put(requestUrl,{}).pipe(
                // catchError(err => {
                //     if ([401,422,  403].indexOf(err.status) !== -1) {
                //         // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                //         this.tokenStorageService.signOut();
                //     }
        
                //     const error = err.error.message || err.statusText;
                //     return throwError(error);
                // }),
                map(result => {
                    self.change.emit("change");
                })
            );
    }

    getCombo(requestMapping: string): Observable<any>{

        var comboUrlLoad = requestMapping;

        if(requestMapping.startsWith("mantenimiento")){
            comboUrlLoad = "mantenimiento";
            const requestUrl = this.urlBase + comboUrlLoad + '/comboByTipo/' + requestMapping.replace("mantenimiento","");
            return this._httpClient.get(requestUrl).pipe(
                map(data => {
                    this.comboLoaded.emit(requestMapping);
                    return data;

                })
            );
        }
        else{
            const requestUrl = this.urlBase + comboUrlLoad + '/combo';
            return this._httpClient.get(requestUrl).pipe(
            map(data => {
                this.comboLoaded.emit(requestMapping);
                return data;

            })
        );
        }

        
    }

    descargaQueryEstadistica(requestMapping: string, id: any) {
        const requestUrl = this.urlBase + requestMapping + '/descargaQueryEstadistica/' + id;
        return this._httpClient.post(requestUrl, {}, {responseType: 'blob'});
    }

    generarInforme(requestMapping: string, tipoReport: string, idReport: number,  id:  any){
        const requestUrl = this.urlBase + requestMapping + '/generarInforme/' + id + '/' + idReport + '/' + tipoReport;
        return this._httpClient.post(requestUrl,{},{ responseType: 'blob' });
    }

    generarInformeJasper(requestMapping: string, tipoReport: string, id:  any){
        const requestUrl = this.urlBase + requestMapping + '/generarInformeJasper/' + id + '/' + tipoReport;
        return this._httpClient.post(requestUrl,{},{ responseType: 'blob' });
    }

    citaTextoPlano(requestMapping: string, id:  any){
        const requestUrl = this.urlBase + requestMapping + '/citaTextoPlano/' + id ;
        return this._httpClient.post(requestUrl,{},{ responseType: 'blob' });
    }



    generarInformePublico(requestMapping: string, tipoReport: string, idReport: number,  id:  any){
        const requestUrl = this.urlBase + requestMapping + '/generarInformePublico/' + id + '/' + idReport + '/' + tipoReport;
        return this._httpClient.post(requestUrl,{},{ responseType: 'blob' });
    }

    download(requestMapping: string, tipoDoc: string,  id:  number){
        const requestUrl = this.urlBase + requestMapping + '/download/' + id + '/' + tipoDoc;
        return this._httpClient.post(requestUrl,{},{ responseType: 'blob' });

    }

    obtenerTraducciones (codigoLenguaje: string, requestMapping: string, id: string) {
        const self = this;
        const requestUrl  = this.urlBase + requestMapping + '/?codigoLenguaje=' + codigoLenguaje +"&id=" + id ;
        return this._httpClient.get(requestUrl,{}).pipe();
    }

    obtenerAlergenosGlobales (codigoLenguaje: string, requestMapping: string, id: string) {
        const self = this;
        const requestUrl  = this.urlBase + requestMapping + '/alergenosGlobales/?codigoLenguaje=' + codigoLenguaje +"&id=" + id ;
        return this._httpClient.get(requestUrl,{}).pipe();
    }

    obtenerIdiomasCliente (requestMapping: string, id: string) {
        const self = this;
        const requestUrl  = this.urlBase + requestMapping + "/idiomasCliente/?id=" + id ;
        return this._httpClient.get(requestUrl,{}).pipe();
    }

    generarQr(requestMapping: string, idPersona: string){
        const requestUrl = this.urlBase + requestMapping  + '/' + idPersona + '/generarQr';
        return this._httpClient.post(requestUrl,{},{ responseType: 'blob' });
    }

    upgrade(requestMapping: string, id: number) {
        const requestUrl = this.urlBase + requestMapping  + '/' + id + '/upgrade';
        return this._httpClient.put(requestUrl, {}).pipe(
            map(result => {
                this.change.emit('change');
            })
        );
    }

    downgrade(requestMapping: string, id: number) {
        const requestUrl = this.urlBase + requestMapping  + '/' + id + '/downgrade';
        return this._httpClient.put(requestUrl, {}).pipe(
            map(result => {
                this.change.emit('change');
            })
        );
    }

    cerrar(requestMapping: string, id: number) {
        const requestUrl = this.urlBase + requestMapping  + '/' + id + '/cerrar';
        return this._httpClient.put(requestUrl, {}).pipe(
            map(result => {
                this.change.emit('change');
            })
        );
    }


    import(id: number, requestMapping: string, tipo: number, file: FormData, massive: boolean){
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/import' + (massive?'Massive':'') + '/' + id + "/" + tipo;
        return this._httpClient.post(requestUrl, file, {});
      
    }

    importLesionData(id: number, requestMapping: string, file: FormData, lesionSeleccionadaText: string){
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/importLesionData' + '/' + id + "/" + lesionSeleccionadaText;
        return this._httpClient.post(requestUrl, file, {});
      
    }

    getLesionData(requestMapping: string, file: FormData){
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/getLesionData';
        return this._httpClient.post(requestUrl, file, {});
      
    }

    obtenerCalendario (requestMapping: string, startingDate: Date, datePipe) {
        const self = this;
        const requestUrl  = this.urlBase + requestMapping + (requestMapping.includes('Dos') ? '/calendarioDos/' : '/calendario/') + datePipe.transform(startingDate, 'dd-MM-yyyy');
        return this._httpClient.get(requestUrl,{}).pipe();
    }

    obtenerCalendarioPermisos (requestMapping: string, startingDate: Date, endingDate: Date, datePipe) {
        const self = this;
        const requestUrl  = this.urlBase + requestMapping + '/calendarioPermisos/' + datePipe.transform(startingDate, 'dd-MM-yyyy') + '/' + datePipe.transform(endingDate, 'dd-MM-yyyy');
        return this._httpClient.get(requestUrl,{}).pipe();
    }

    obtenerCalendarioTareas (requestMapping: string, startingDate: Date, endingDate: Date, datePipe) {
        const self = this;
        const requestUrl  = this.urlBase + requestMapping + '/calendarioTareas/' + datePipe.transform(startingDate, 'dd-MM-yyyy') + '/' + datePipe.transform(endingDate, 'dd-MM-yyyy');
        return this._httpClient.get(requestUrl,{}).pipe();
    }

    obtenerTabla (requestMapping: string, id: string) {
        const self = this;
        const requestUrl  = this.urlBase + requestMapping + '/' + id + '/tabla';
        return this._httpClient.get(requestUrl,{}).pipe();
    }

    obtenerTablaDashboard (requestMapping: string) {
        const self = this;
        const requestUrl  = this.urlBase + requestMapping + '/tablaDashboard';
        return this._httpClient.get(requestUrl,{}).pipe();
    }

    obtenerGraficaDashboard (requestMapping: string) {
        const self = this;
        const requestUrl  = this.urlBase + requestMapping + '/graficaDashboard';
        return this._httpClient.get(requestUrl,{}).pipe();
    }

    obtenerProcesos (requestMapping: string) {
        const self = this;
        const requestUrl  = this.urlBase + requestMapping + '/obtenerSelectorProcesos';
        return this._httpClient.get(requestUrl,{}).pipe();
    }

    descargarExcelCompleto(requestMapping: string, tipo: string) {
        const requestUrl = this.urlBase + requestMapping + '/descargarExcel';
        return this._httpClient.post(requestUrl, {}, {responseType: 'blob'});
    }

    registerStart(){
        const requestUrl = AUTH_API + 'register-biometric-start';
        return this._httpClient.get(requestUrl,{}).pipe();
    }

    registerFinish(credentials){
        const requestUrl = AUTH_API + 'register-biometric-finish';
        return this._httpClient.post(requestUrl,credentials).pipe();
    }

    deletePacs(id: number, idTipo: number, requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/deletePacs?id=' + id + "&tipo="+idTipo;
        return this._httpClient.put(requestUrl,{}).pipe(
                map(result => {
                    self.change.emit("change");
                })
            );
    }

    downloadPacs(requestMapping: string, id:  number, idTipo: number){
        const requestUrl = this.urlBase + requestMapping + '/downloadPacs/' + id + '/'+idTipo;
        return this._httpClient.post(requestUrl,{},{ responseType: 'blob' });

    }

    getListFiles(requestMapping: string, id:  number, idTipo: number) {
        const requestUrl = this.urlBase + requestMapping + '/listFiles/' + id + '/'+idTipo;
        return this._httpClient.get(requestUrl);
    }


    anonymize(id: number, requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping  + '/' + id + '/anonymize/';
        return this._httpClient.put(requestUrl,{}).pipe(
                map(result => {
                    self.change.emit("change");
                })
            );
    }

    rejectAnatomicalCase(id: number, requestMapping: string) {
        var self=this;
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/rejectAnatomical';
        return this._httpClient.put(requestUrl,{}).pipe(
            map(result => {
                self.change.emit("change");
            })
        );
    }

    guardarTareaPersona(calendario, requestMapping: string) {
        const requestUrl = this.urlBase + requestMapping + '/guardarTareaPersona';
        return this._httpClient.post(requestUrl, calendario, {});
    }

    
    executeAll(parameters:any) {
        var self=this;
        const requestUrl = this.urlBase + 'dashboardinforme/executeAll';
        return this._httpClient.put<any[]>(requestUrl,parameters);
    }

    lastExecution() {
        const requestUrl = this.urlBase+ 'dashboardinforme/lastExecution';
        return this._httpClient.get<any[]>(requestUrl)
    }

    obtenerUltimaEjecucion() {
        const requestUrl = this.urlBase + 'execution/obtenerUltimaEjecucion';
        return this._httpClient.post(requestUrl, {});
    }
    
    exportStats(id, userCode){

        var json ={
            code: userCode
        }
        const requestUrl = this.urlBase + 'dashboardinforme' + '/exportStats/' + id;
        return this._httpClient.post(requestUrl,{json},{ responseType: 'blob' });
    }



}
