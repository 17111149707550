import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {TokenStorageService} from '../../_services/token-storage.service';
import {Role} from '../../_models/role';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon?: string;
    class: string;
    authenticate?: Boolean;
    roles?: Role[];
    separatorAfter?:boolean,
    submenus?:RouteInfo[],
    modulos?:string[]
}
export const ROUTES: RouteInfo[] = [

    { path: '/citaCuatro', title: 'Cases',  icon: 'dashboard', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos:['qfr']},

    { path: '', title: 'Cases',  icon: 'event', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['bd', 'citacion'],
        submenus: [
            { path: '/listaEspera', title: 'Lista de espera',  icon: 'hourglass_empty', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['citacion']},
            { path: '/citaPrevia', title: 'Citas',  icon: 'event', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['citacion']},
            { path: '/cita', title: 'Eventos',  icon: 'event', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['bd']}
        ]
    },

    { path: '', title: 'Citación',  icon: 'event', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['bdDos', 'citacionDos'],
        submenus: [
            { path: '/listaEsperaDos', title: 'Lista de espera',  icon: 'hourglass_empty', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['bdDos']},
            { path: '/citaPreviaDos', title: 'Citas',  icon: 'event', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['citacionDos']},
            { path: '/citaDos', title: 'Eventos',  icon: 'event', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['bdDos']}
        ]
    },

    { path: '', title: 'Citación',  icon: 'event', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['bdTres'],
        submenus: [
            { path: '/listaEsperaTres', title: 'Lista de espera',  icon: 'hourglass_empty', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['bdTres']},
            { path: '/citaPreviaTres', title: 'Citas',  icon: 'event', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['bdTres']},
            { path: '/citaTres', title: 'Eventos',  icon: 'event', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['bdTres']}
        ]
    },

    { path: '', title: 'Citación',  icon: 'event', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['bdCinco'],
        submenus: [
            { path: '/listaEsperaCinco', title: 'Lista de espera',  icon: 'hourglass_empty', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['bdCinco']},
            { path: '/citaPreviaCinco', title: 'Citas',  icon: 'event', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['bdCinco']},
            { path: '/citaCinco', title: 'Estudios',  icon: 'event', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['bdCinco']}
        ]
    },

    // { path: '/dashboardTrial', title: 'Dashboard',  icon: 'dashboard', class: '', authenticate: true, roles: [Role.Admin], modulos:['crd']},
    // { path: '/dashboardTrialDos', title: 'Dashboard',  icon: 'dashboard', class: '', authenticate: true, roles: [Role.Admin], modulos:['crd']},
    { path: '/dashboardTrialTres', title: 'Dashboard',  icon: 'dashboard', class: '', authenticate: true, roles: [Role.Admin], modulos:['crd']},
    // { path: '/trial', title: 'Casos',  icon: 'person', class: '', authenticate: true, roles: [Role.Admin,Role.User, Role.Monitor], modulos:['crd']},
    { path: '/trialTres', title: 'Casos',  icon: 'person', class: '', authenticate: true, roles: [Role.Admin,Role.User, Role.Monitor, Role.Corelab], modulos:['crd']},
    // { path: '/trialDos', title: 'Casos 2',  icon: 'person', class: '', authenticate: true, roles: [Role.Admin,Role.User, Role.Monitor], modulos:['crd']},

    //{ path: '/consultaTrial', title: 'Querys',  icon: 'chat', class: '', authenticate: true, roles: [Role.Admin, Role.User, Role.Monitor], modulos:['crd']},
    { path: '/consultaTrialTres', title: 'Querys case',  icon: 'chat', class: '', authenticate: true, roles: [Role.Admin, Role.User, Role.Monitor], modulos:['crd']},
    { path: '/consultaSerumLab', title: 'Querys serum lab',  icon: 'chat', class: '', authenticate: true, roles: [Role.Admin, Role.User, Role.Monitor], modulos:['crd']},
    { path: '/centro', title: 'Centros',  icon: 'business', class: '', authenticate: true, roles: [Role.Admin], modulos:['crd','bdTres']},

  //  { path: '/paciente', title: 'Pacientes',  icon: 'person', class: '', authenticate: true, roles: [Role.AdminRole.User], separatorAfter: true},
  //  { path: '/solicitud', title: 'Solicitudes',  icon: 'description', class: '', authenticate: true, roles: [Role.AdminRole.User]},
    // { path: '/tipoCita', title: 'Tipos de cita',  icon: 'category', class: '', authenticate: true, roles: [Role.Admin]},
   
   { path: '/cuadroMando', title: 'Estadísticas',  icon: 'games', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos:['bd']},

    { path: '/notificacion', title: 'Notificaciones',  icon: 'notifications', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['bd', 'notificaciones']},
   

   { path: '', title: 'Artículos',  icon: 'package', class: '', authenticate: true, roles: [Role.Admin], modulos:['almacen'], submenus:[
    { path: '/familia', title: 'Familias',  icon: 'hotel_class', class: '', authenticate: true, roles: [Role.Admin]},
    { path: '/articulo', title: 'Artículos',  icon: 'article', class: '', authenticate: true, roles: [Role.Admin]},
   ]},
   
   { path: '', title: 'Agenda',  icon: 'calendar_month', class: '', authenticate: true, roles: [Role.Admin], modulos: ['agenda'],
   submenus: [
       { path: '/persona', title: 'Personas',  icon: 'face', class: '', authenticate: true,  roles: [Role.Admin], modulos: ['agenda']},
       { path: '/tarea', title: 'Tareas',  icon: 'task_alt', class: '', authenticate: true,  roles: [Role.Admin], modulos: ['agenda']},
       { path: '/permiso', title: 'Permisos',  icon: 'free_cancellation', class: '', authenticate: true,  roles: [Role.Admin], modulos: ['agenda']},
   ]
  },

   { path: '', title: 'Configuración usuario',  icon: 'settings', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos:['bd','bdTres','bdCinco'], submenus:[
    { path: '/dispositivo', title: 'Dispositivos',  icon: 'format_list_bulleted', class: '', authenticate: true, separatorAfter:true, roles: [Role.Admin, Role.User], modulos:['bd','bdCinco']},
    { path: '/tipoDispositivo', title: 'Tipos de dispositivos',  icon: 'format_list_bulleted', class: '', authenticate: true, separatorAfter:true, roles: [Role.Admin, Role.User], modulos:['bd','bdCinco']},
    { path: '/estudio', title: 'Estudios',  icon: 'lab', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos:['bd','bdCinco']},
    { path: '/facultativo', title: 'Facultativos',  icon: 'badge', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos:['bd','bdCinco']},
    { path: '/farmaco', title: 'Fármacos',  icon: 'badge', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos:['bdCinco']},
    { path: '/unidad', title: 'Unidades',  icon: 'badge', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos:[,'bdCinco']},
    { path: '/problema', title: 'Complicaciones',  icon: 'badge', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos:[,'bdCinco']},
    { path: '/solucion', title: 'Soluciones',  icon: 'badge', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos:[,'bdCinco']}
   
  ]},

   { path: '', title: 'Configuración admin',  icon: 'settings', class: '', authenticate: true, roles: [Role.Admin], modulos:['bd', 'bdTres','bdCinco'],
    submenus:[  
      { path: '/tipoMantenimiento', title: 'Tipos de mantenimiento',  icon: 'format_list_bulleted', class: '', authenticate: true, roles: [Role.Admin], modulos:['bd']},
      { path: '/proceso', title: 'Procesos',  icon: 'format_list_bulleted', class: '', authenticate: true, roles: [Role.Admin], modulos:['bd','bdCinco']},
      { path: '/mantenimiento', title: 'Mantenimientos',  icon: 'format_list_bulleted', class: '', authenticate: true, roles: [Role.Admin], modulos:['bd']},
      { path: '/factorRiesgo', title: 'Factores de tiesgo',  icon: 'heart_broken', class: '', authenticate: true, roles: [Role.Admin], modulos:['bd','bdTres']},
      { path: '/accion', title: 'Tipos acción',  icon: 'format_list_bulleted', class: '', authenticate: true, roles: [Role.Admin], modulos:['bd','bdCinco']},
      { path: '/oclusion', title: 'Oclusiones',  icon: 'format_list_bulleted', class: '', authenticate: true, roles: [Role.Admin], modulos:['bd','bdCinco']},
      { path: '/complicacion', title: 'Complicaciones',  icon: 'format_list_bulleted', class: '', authenticate: true, roles: [Role.Admin], modulos:['bd']},
      { path: '/clinica', title: 'Clínicas',  icon: 'format_list_bulleted', class: '', authenticate: true, roles: [Role.Admin], modulos:['bdCinco']},
      { path: '/coils', title: 'Tipos de coils',  icon: 'format_list_bulleted', class: '', authenticate: true, roles: [Role.Admin], modulos:['bdCinco']},
      { path: '/hospital', title: 'Hospitales',  icon: 'business', class: '', authenticate: true, roles: [Role.Admin], modulos:['bd', 'bdTres', 'bdCinco']},
      { path: '/diagnostico', title: 'Diagnosticos',  icon: 'business', class: '', authenticate: true, roles: [Role.Admin], modulos:['bdTres']},
      
        { path: '/servicio', title: 'Servicios',  icon: 'badge', class: '', authenticate: true, roles: [Role.Admin], modulos:['bdDos']},
    ]
  },


    { path: '', title: 'Agenda',  icon: 'calendar_month', class: '', authenticate: true, roles: [Role.Admin], modulos: ['agenda'],
        submenus: [
            { path: '/calendarioTareas', title: 'Calendario de tareas',  icon: 'face', class: '', authenticate: true,  roles: [Role.Admin], modulos: ['agenda']},
            { path: '/persona', title: 'Personas',  icon: 'face', class: '', authenticate: true,  roles: [Role.Admin], modulos: ['agenda']},
            { path: '/tarea', title: 'Tareas',  icon: 'task_alt', class: '', authenticate: true,  roles: [Role.Admin], modulos: ['agenda']},
            { path: '/permiso', title: 'Permisos',  icon: 'free_cancellation', class: '', authenticate: true,  roles: [Role.Admin], modulos: ['agenda']},
        ]
    },

    { path: '', title: 'Video',  icon: 'video_camera_back', class: '', authenticate: true, roles: [Role.Admin], modulos: ['video'],
        submenus: [
            { path: '/video', title: 'Videos',  icon: 'video_camera_back', class: '', authenticate: true,  roles: [Role.Admin], modulos: ['video'] },
            { path: '/etiqueta', title: 'Etiquetas',  icon: 'video_camera_back', class: '', authenticate: true,  roles: [Role.Admin], modulos: ['video'] }
        ]
    },

    { path: '', title: 'Estadísticas',  icon: 'inventory', class: '', authenticate: true, roles: [Role.Admin, Role.User], modulos: ['stats'],
        submenus: [
            { path: '/informe', title: 'Dashboard',  icon: 'inventory', class: '', authenticate: true,  roles: [Role.Admin, Role.User], modulos: ['stats'] },
            { path: '/dashboardinforme', title: 'Def. Dashboards',  icon: 'inventory', class: '', authenticate: true,  roles: [Role.Admin], modulos: ['stats'] },
            { path: '/stat', title: 'Def. Estadísticas',  icon: 'inventory', class: '', authenticate: true,  roles: [Role.Admin], modulos: ['stats'] }
        ]
    },

  { path: '', title: 'Usuarios',  icon: 'group', class: '', authenticate: true, roles: [Role.Admin], submenus:[
    { path: '/user', title: 'Usuario',  icon: 'group', class: '', authenticate: true,  roles: [Role.Admin]},
    { path: '/cliente', title: 'Clientes',  icon: 'support_agent', class: '', authenticate: true, roles: [Role.Admin]}
  ]}


];
//
// export const ROUTES_NO_LDAP: RouteInfo[] = [
//   { path: '/user-profile', title: 'User Profile',  icon: 'person', class: '', authenticate: true }
// ];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css' ]
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  environment = environment;
  constructor( private tokenStorageService: TokenStorageService, private domSanitizer: DomSanitizer, private router: ActivatedRoute,) {}
  currentRout: any;

  ngOnInit() {
    
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    // this.menuItems.push(ROUTES_NO_LDAP.filter(menuItem => menuItem)[0]);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };

  

  logout(): void {
    this.tokenStorageService.signOut();
    window.location.reload();
  }

  authenticationCheckBoolean(): boolean{
    return this.tokenStorageService.getToken() != null;
  }

  authenticationCheck(r:RouteInfo): boolean{

    if(r.authenticate != null){
      if(r.authenticate){
      

        if(this.tokenStorageService.getToken() != null){
          if(r.roles!=null){
  
            let counter = 0;
            for(var i = 0; i < r.roles.length; i++) {
                if(this.tokenStorageService.getUser().roles.includes(r.roles[i])){
                    counter++;
                } 
            }
    
            return counter>0;
    
    
          }
          else{
            return true;
          }
        }
        else{
          return false;

        }
        
  
      }
      else{
        return this.tokenStorageService.getToken() == null;
      }
      
    }
    else{
      return true;
    }
   
    

  }

  isActivatedModule(menuItem){

    return environment.modulos == undefined || menuItem.modulos == undefined || menuItem.modulos.some(r=> environment.modulos.includes(r))

  }
  
}
