import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { Router } from '@angular/router';
import { TokenStorageService } from './../../_services/token-storage.service';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { OptionCombo } from '../../table-list/detail/field.interface';
import { PantasmaHttpClient } from '../../table-list/shared/pantasma-htttp-client.component';
import {    UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { catchError, tap } from 'rxjs/operators';
import {create} from '@github/webauthn-json';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

    navbarForm: UntypedFormGroup;
    private listTitles: any[];
    location: Location;
      mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible: boolean;
    environment = environment;
    user: any;
    filteredValues: OptionCombo[] = [];
    clienteOptions: any[] = [];
    loadUser:boolean = false;
 
    constructor(location: Location,  
        private element: ElementRef, 
        private router: Router, 
        private tokenStorageService: TokenStorageService,  
        private domSanitizer: DomSanitizer,  
        public pantasmaHttpClient:  PantasmaHttpClient,
        private fb: UntypedFormBuilder) {
      this.location = location;
          this.sidebarVisible = false;
    }

    ngOnInit(){
      
      
      var self = this;
      this.navbarForm = this.fb.group({clienteSelector:['']});
      this.listTitles = ROUTES.filter(listTitle => listTitle);
      const navbar: HTMLElement = this.element.nativeElement;
      this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
      this.router.events.subscribe((event) => {
         var $layer: any = document.getElementsByClassName('close-layer')[0];
         if ($layer) {
           $layer.remove();
           this.mobile_menu_visible = 0;
         }
          
     });
     
     if(!this.loadUser){
        this.user=this.tokenStorageService.getUser();
        
        if(this.user!=null && this.user.clienteId == null){
            this.loadUser=true;
            this.getComboClientes();
        }
        
      }

       
     self.navbarForm.get("clienteSelector").valueChanges.subscribe(selectedValue => {

        self.filteredValues = self.filterValues(selectedValue);
        if( typeof  selectedValue == 'object'){
            self.tokenStorageService.saveClient(selectedValue);
        }
        
        
     });

    
    
    }

    
    createControl() {
        const group = this.fb.group({});
      
            const control = this.fb.control(
                {value:undefined},
                
            );

            group.addControl('clienteSelector', control);
              
        return group;
    }


    getUsername(){

        var user = this.tokenStorageService.getUser();
        return user!=undefined?user.name:'';

    }

    logout(): void {
        this.tokenStorageService.signOut();
        window.location.reload();
    }
   
    register(): void {
            
            this.pantasmaHttpClient.registerStart().pipe(
                tap(data2 => {
                }),
                catchError(err => {
                    return observableOf([]);
                })
            ).subscribe(response => {
                this.registerFinish(response);
            });
    }

    async registerFinish(response): Promise<void> {
        delete response['publicKeyCredentialCreationOptions']['extensions']['appidExclude'];      

        const credential = await create({
          publicKey: response.publicKeyCredentialCreationOptions
        });
        alert("A"+credential);
        try {
          // @ts-ignore
          credential.clientExtensionResults = credential.getClientExtensionResults();
          alert("B"+credential);
        } catch (e) {
            alert(e);
          // @ts-ignore
          credential.clientExtensionResults = {};
          alert("C"+credential);
        }
    
        const credentialResponse = {
          registrationId: response.registrationId,
          credential
        };
        alert(credentialResponse);
        this.pantasmaHttpClient.registerFinish(credentialResponse).pipe(
            tap(data2 => {
            }),
            catchError(err => {
                return observableOf([]);
            })
        ).subscribe(response2 => {
           alert(response2);
        });
        
    }


    displayFn(options: OptionCombo[]): (id: any) => any | null {
        return (id: any) => {
          const correspondingOption = Array.isArray(options)
              ? options.find(option => option.key === '' + (id !== undefined && id != null ? id.key : ''))
              : null;
          return correspondingOption ? correspondingOption.label : '';
        }
    }

    filterValues(search: any) {
        
        if(search!=undefined && search.key != undefined){
            return this.filterByDependent(this.clienteOptions);
        }
        else{
            if(this.clienteOptions!=undefined){
                const results =  this.clienteOptions.filter(value =>
                    value.label.toLowerCase().indexOf(search!=null?search.toLowerCase():'') !== -1);
                return this.filterByDependent(results);
            }else{
                return null;
            }
            
        }
       

       
    }
    
    filterByDependent(elementsToFilter){

       return elementsToFilter;
       
    }

    getComboClientes(){

        var self = this;
        if(this.clienteOptions == null || this.clienteOptions.length==0){
            
            
            if(this.tokenStorageService.getClientOptions()!=undefined){

                setTimeout(function(){
                    self.clienteOptions=[];
                    var options = [];
                    self.tokenStorageService.getClientOptions().forEach(element => {
                        options.push({
                            key:  element.key ,
                            label: element.label,
                            additionalInfo: element.additionalInfo
                        });
                    });
                    self.tokenStorageService.saveClientOptions(options);
                    self.clienteOptions=options;
                    if(self.tokenStorageService.getClient()!=undefined){
                        self.navbarForm.get("clienteSelector").setValue(self.tokenStorageService.getClient());
                    }
                    else{
                        self.filteredValues = self.filterValues('');
                    }
                },500);
            }
            else {
                var self = this;
                self.clienteOptions = [];
                if (this.loadUser) {
                    this.pantasmaHttpClient.getCombo('cliente').subscribe(data => {

                            var options = [];
                            data['items'].forEach(element => {
                                options.push({
                                    key: element.key,
                                    label: element.label,
                                    additionalInfo: element.additionalInfo
                                });
                            });
                            self.tokenStorageService.saveClientOptions(options);


                            self.clienteOptions = options;
                            if (this.tokenStorageService.getClient() != undefined) {
                                self.navbarForm.get("clienteSelector").setValue(this.tokenStorageService.getClient());
                            } else {
                                self.filteredValues = self.filterValues('');
                            }
                    });
                }
            }
        }
       
    }

     

}
function observableOf(arg0: undefined[]): any {
    throw new Error('Function not implemented.');
}

